

.pruebaNos{
    width:128px;
    font-size:48px; 
    font-weight:700;
    font-family: 'Segoe UI', Arial, sans-serif;
    display: inline-flex!important;
    margin: 0!important;
    color:#2e7d32!important
}

.pruebaNos4{
    width:180px;
    font-size:48px; 
    font-weight:700;
    font-family:  'Segoe UI', Arial, sans-serif!important;
    display: inline-flex!important;
    margin: 0!important;
    color:#2e7d32!important;
   
}

.pruebaNos5{
    width:160px;
    font-size:56px; 
    font-weight:700;
    font-family: 'Segoe UI', Arial, Helvetica, sans-serif;
    display: inline-flex!important;
    margin: 0!important;
    color:#2e7d32!important;
    z-index: 100!important;
}





.trabajemosNosotros{
    cursor: pointer !important;
    text-decoration: underline!important;
}

.trabajemosNosotros:hover{
    color: dimgray !important;
}

.botonNoso:hover{
     background: #D0D0D0!important;
}



.pruebafootNoso{
    z-index: 1!important;
}

.absolCirculSvg{
    position: absolute;
}

.imageCardNosWrap{
    position: relative; 
    padding-bottom: calc(2628 / 5472 * 100%);
}

.imageCardNos{
    position: absolute;
    width: 100%; 
}

.comillaUno{
    transform: rotate(180deg) translate(0px,25px) !important;
    font-family: Lato!important;
    font-size: 70px!important;
    position: absolute!important;
    left:calc(5vw + 12px)!important;
    
}

@media (max-width:900px){
    .comillaUno{ 
        transform: rotate(180deg) translate(0px,20px) !important;
        font-size: 50px!important;
        left:calc(5vw + 0px)!important; 
    }

}

@media (max-width:600px){
    .comillaUno{
        transform: rotate(180deg) translate(0px,10px) !important;
        font-size: 30px!important;
        left:0px!important;
    }

}

.comillaDos{

    font-family: Lato!important;
    font-size: 70px!important;
    position: absolute!important;
    right:calc(5vw + 0px)!important;
}

@media (max-width:900px){
    .comillaDos{ 
        font-size: 50px!important;
        right:calc(5vw + 0px)!important; 
    }

}

@media (max-width:600px){
    .comillaDos{
        font-size: 30px!important;
        right:0px!important;
    }

}

.nosMod1{
    width:100%!important;
    margin-left:0px!important;
margin-right:0px!important;
}



@media (min-width:600.01px){
    .nosMod1{
width:60%!important;
    }
}

@media (min-width:900.01px){
    .nosMod1{
margin-left:50px!important;
margin-right:100px!important;
    }
}

.nosMod2{
    width:100%!important;
}



@media (min-width:600.01px){
    .nosMod2{
width:40%!important;
    }
}

.nosMod3{
    padding-right:0px!important;
    padding-left:0px!important;
    right:5vw!important;
}




@media (min-width:600.01px){
    .nosMod3{
padding-right:5vw!important;
padding-left:5vw!important;
right:0.5vw!important;
    }
}

@media (min-width:900.01px){
    .nosMod3{
right:0.5vw!important;
    }
}



.nosMod4{
    max-width: 35vw!important;
    font-size: 12px!important;
    padding: 8px!important;
}


@media (min-width:600.01px){
    .nosMod4{
        font-size: 14px!important;
        padding: 12px!important;
    }
}

@media (min-width:900.01px){
    .nosMod4{
        max-width: 400px!important;
        font-size: 20px!important;
        padding: 24px!important;
    }
}

.nosMod5{
    width: 100%!important;
}

@media (min-width:900.01px){
    .nosMod5{
        width: 50%!important;
    }
}

.nosMod6{
margin-left: 0px!important;
margin-right: 0px!important;
}

@media (min-width:900.01px){
    .nosMod6{
        margin-left: 50px!important;
        margin-right: 50px!important;
    }
}


.nosMod7{
    margin-left: 0px!important;
    margin-right: 0px!important;
    font-size: 32px!important;
    width: 100%!important;
    }
    
    @media (min-width:600.01px){
        .nosMod7{
         font-size: 48px!important;
        }
    }

    @media (min-width:900.01px){
        .nosMod7{
            margin-left: 50px!important;
            margin-right: 50px!important;
            width: calc(100% - 50px)!important;
        }
    }
    

    .nosMod8{
        display: block!important;
    }
    
    @media (min-width:900.01px){
        .nosMod8{
            display: flex!important;
        }
    }